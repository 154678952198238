import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function Welcome() {
  return (
    <Layout>
      <Seo title="Micrantha Nursery" />
      <div id="placeholder" class="m-5">
        <h1>Site is temporarily unavailable.</h1>
        <p>This application is still being designed. Please check back soon.</p>
        <p id="signature">&mdash; <a href="mailto:nursery@micrantha.com">Admin</a></p>
      </div>
    </Layout>
  )
}

export default Welcome